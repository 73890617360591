<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4">
                <button
                  v-if="berechtigungen.b_bildung_noteneingabe.update"
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success mr-2"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>
                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="mr-4">
                <button 
                  v-if="berechtigungen.b_bildung_noteneingabe.delete"
                  class="btn btn-danger" 
                  @click="oeffneLoeschenModal"
                >
                  <font-awesome-icon icon="fa-duotone fa-trash" class="mr-2" />
                  {{ $t("global.delete") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-12 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row mt-2">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="note.noteneingabe_titel"
                      class="form-control mt-2 mb-3"
                      placeholder=" "
                      readonly
                    />
                    <span class="openIcon" v-on:click="goToNoteneingabe"
                      ><font-awesome-icon
                        icon="fa-duotone fa-arrow-circle-right"
                      />
                    </span>
                    <label class="t-8">{{ $t("global.scoreinput") }}</label>
                  </div>
                </div>
                <div class="col-xl-3">
                  <div class="form-group">
                    <input
                      v-model.trim="note.teilnehmer.personName"
                      class="form-control mt-2 mb-3"
                      placeholder=" "
                      readonly
                    />
                    <span class="openIcon" v-on:click="goToTeilnehmer"
                      ><font-awesome-icon
                        icon="fa-duotone fa-arrow-circle-right"
                      />
                    </span>
                    <label class="t-8">{{ $t("global.participants") }}</label>
                  </div>
                </div>
                <div class="col-xl-3">
                  <div class="form-group">
                    <input
                      v-model.trim="note.textwahl_wert"
                      class="form-control mt-2 mb-3"
                      placeholder=" "
                      :readonly="!editable"
                    />
                    <label class="t-8">{{
                      $t("global.valuetextchoice")
                    }}</label>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-xl-6">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="note.punkte"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.points") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="note.notendurchschnitt_prov"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.gradeaverageprov") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="note.korrektur"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.correction") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="note.notendurchschnitt_def"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.gradeaveragedef") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="note.eingegebene_praesenz"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.enteredpresence") }}</label>
                      </div>
                    </div>

                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="note.anzahl_eingaben"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.numberofinputs") }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          v-model.number="note.berechnete_praesenz"
                          class="form-control"
                          placeholder=" "
                          :readonly="!editable"
                        />
                        <label>{{ $t("global.calculatedpresence") }}</label>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-check">
                        <input
                          v-model="note.dispensiert"
                          class="form-check-input"
                          placeholder=" "
                          type="checkbox"
                          :disabled="!editable"
                        />
                        <label>{{ $t("global.dispensed") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      v-model.trim="note.textbewertung"
                      class="form-control"
                      placeholder=" "
                      rows="4"
                      :readonly="!editable"
                    ></textarea>
                    <label>{{ $t("global.textrating") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
      </div>
      <!-- ENDE linke Seite -->

      <!--
      <div v-if="id" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 0 }"
                  :to="{
                    name: 'RaumMitIdUndAnzeige',
                    params: { id: id, anzeige: 0 },
                  }"
                  >Termin</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 1 }"
                  :to="{
                    name: 'RaumMitIdUndAnzeige',
                    params: { id: id, anzeige: 1 },
                  }"
                  >Sperrtermin</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-xl-12 p-0">
            <div class="tab-container p-3"></div>
          </div>
        </div>
      </div>
      -->
    </div>
    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="mr-2 text-warning"
              />{{ $t("global.deletegrade") }}
            </h4>
          </div>
          <div class="modal-body">{{ $t("global.doyouwantdeletegrade") }}</div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";

export default {
  name: "Note",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    LoadingOverlay,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        if (!this.id) {
          return this.$t("global.newgrade");
        }
        return `${this.$t("global.grade")} ${this.id}`;
      },
    };
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      note: {
        noteneingabe: {},
        teilnehmer: { personName: this.$t("global.notlinked") },
      },
      bezeichnung: null,
      kurzbezeichnung: null,
      bemerkung: null,
      gebaeude: null,
      gebaeudeliste: [],
      raumgruppe: null,
      raumgruppenliste: [],
      geschoss: null,
      flaeche: null,
      sitzplaetze: null,
      loading: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id) {
        return `${this.$t("global.grade")} ${this.id}`;
      } else {
        return this.$t("global.newgrade");
      }
    },
  },
  created() {
    Api.get("noten/", { params: { id: this.id } }).then((response) => {
      this.initializeNote(response.data);
    });
  },
  mounted: function () {},
  methods: {
    goToNoteneingabe() {
      if (this.note.noteneingabe.id) {
        this.$router.push({
          name: "NoteneingabeMitId",
          params: { id: this.note.noteneingabe.id, anzeige: 0 },
        });
      }
    },
    goToTeilnehmer() {
      if (this.note.noteneingabe.id) {
        this.$router.push({
          name: "GeschaeftspartnerMitIdUndAnzeige",
          params: { id: this.note.teilnehmer.id, anzeige: 0 },
        });
      }
    },
    abbrechen() {
      this.$router.push({ name: "Notenliste" });
    },
    initializeNote(note) {
      if (note) {
        this.note = note;
        if (!this.note.teilnehmer)
          this.note.teilnehmer = { personName: this.$t("global.notlinked") };
      } else {
        console.error("note ist null");
      }
      this.editable = this.berechtigungen.b_bildung_noteneingabe.update;
    },

    speichern() {
      if (this.loading) return;

      var json = Object.assign({}, this.note);

      if (json.teilnehmer) json.teilnehmer = this.note.teilnehmer.id;
      if (json.noteneingabe) json.noteneingabe = this.note.noteneingabe.id;
      if (json.textwahl_wert) json.textwahl_wert = this.note.textwahl_wert.id;

      this.loading = true;

      if (!this.id) {
        Api.post("noten/", json)
          .then((response) => {
            this.initializeNote(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.gradesavedsuccessfully"),
            });

            this.$router.replace({
              name: "NotenMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("noten/", json, { params: { id: this.note.id } })
          .then((response) => {
            this.initializeNote(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.gradesavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("noten/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "Notenliste" });
      });
    },
  },
};
</script>

<style></style>
